//import 'slick-carousel';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

var FeatureInit = function(){
  var $window = $(window);

  var $contentSlide = $(".contents-slide");
  var $contentSlideBtn = $contentSlide.find(".contents-slide--btn");
  var $contentSlideBox = $contentSlide.find(".contents-slide-box");
  var ContentShow = function(){
    if($contentSlideBox.css("display") === "block"){
      $contentSlideBox.stop().slideUp(300);
      $contentSlideBtn.text("show").removeClass("open");
    }else{
      $contentSlideBox.stop().slideDown(300);
      $contentSlideBtn.text("hide").addClass("open");
    }
  };
  $contentSlideBtn.on("click", ContentShow);

  var swipers = new Swiper('.swiper', {
    spaceBetween: 0,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
  });


  // share
  var pageTitle = encodeURIComponent($("title").text());
  var pageURL = encodeURIComponent(location.href);

  //$("#share_tw").attr("href", "https://twitter.com/intent/tweet?text=" + pageTitle + "&url=" + pageURL);
  //$("#share_fb").attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + pageURL);
  //$("#share_line").attr("href", "https://social-plugins.line.me/lineit/share?url=" + pageURL);


  // Resize
  var Resize = function(){
  };

  $window.on("resize", Resize);
  Resize();

  AnimationStart($("#signboard"));
};

window.addEventListener("load", FeatureInit);